export const transactionalAdvisoryQuery = `// groq
*[_type == 'transactionalAdvisory'][0] {
  seo {
    title,
    keywords,
    description
  },
  firstSection {
    title,
    cateogry
  },
  secondSection {
    title,
    "_rawDescription": description,
    mainImage {
      asset-> {
        url
      }
    }
  },
  thirdSection {
    title,
    infographicImage {
      asset-> {
        url
      }
    }
  }
}
`;
