import React, { Fragment, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { SEO, TransactionalFirstSection, TransactionalSecondSection, TransactionalThirdSection } from '../components';
import { SEO_TEXT } from '../constants';
import { SanityTransactionalType } from '../utils/globalTypes';
import { getClient } from '../utils/sanity.client';
import { transactionalAdvisoryQuery } from '../utils/querys/transactionalAdvisory/query';

interface Props {
  data: {
    sanityTransactionalAdvisory: SanityTransactionalType;
  };
}

const TransactionalAdvisory = ({ data: { sanityTransactionalAdvisory } }: Props) => {
  const [advisoryData, setAdvisoryData] = useState<any>();

  useEffect(() => {
    const url = new URL(location.href);
    const getData = async () => {
      const client = getClient({ token: process.env.GATSBY_SANITY_READ_TOKEN || '' });
      const data = await client.fetch(transactionalAdvisoryQuery);
      setAdvisoryData(data);
    };
    if (advisoryData === undefined && url.search === '?preview_mode=true') getData();
  }, [advisoryData]);
  return (
    <Fragment>
      <SEO
        title={sanityTransactionalAdvisory.seo?.title || ''}
        description={sanityTransactionalAdvisory.seo?.description || ''}
      />
      <TransactionalFirstSection
        content={advisoryData ? advisoryData.firstSection : sanityTransactionalAdvisory.firstSection}
      />
      <TransactionalSecondSection
        content={advisoryData ? advisoryData.secondSection : sanityTransactionalAdvisory.secondSection}
      />
      <TransactionalThirdSection
        content={advisoryData ? advisoryData.thirdSection : sanityTransactionalAdvisory.thirdSection}
      />
    </Fragment>
  );
};

export default TransactionalAdvisory;

export const pageQuery = graphql`
  query {
    sanityTransactionalAdvisory {
      seo {
        title
        keywords
        description
      }
      firstSection {
        title
        cateogry
      }
      secondSection {
        title
        _rawDescription
        mainImage {
          asset {
            gatsbyImageData(fit: CROP)
          }
        }
      }
      thirdSection {
        title
        infographicImage {
          asset {
            gatsbyImageData(fit: SCALE)
          }
        }
      }
    }
  }
`;
